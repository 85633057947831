/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

 import holographic from './assets/holographic.jpg'

export default {
  bhwhite: {
    bg: 'white',
    mt: '75px',
  },
  heroContainer: {
    position: `relative`,
    py: [3],
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      width: `full`,
      height: `100%`,
      top: [`25%`, null, null, `50%`],
      right: `50%`,
      transform: `translate(50%, -50%)`,
      zIndex: 0,
      borderRadius: `xl`
    },
    '::after': {
      // background: `linear-gradient(
      //     180deg,
      //     rgba(255,255,255,0.2) 0%,
      //     rgba(255,255,255,0.9) 100%
      //   )`
    },
    '::before': {
      background: `url(${holographic}) no-repeat center center`,
      backgroundSize: `cover`
     
    },
  },
}
